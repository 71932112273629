<template>
  <el-dialog
    append-to-body
    width="460px"
    title=""
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <template #title>
      <div class="el-dialog-title">申请提现</div>
    </template>

    <el-form
      v-loading="loading"
      class="saft_form"
      element-loading-text="加载中…"
      ref="elFormDom"
      label-width="130px"
      label-position="top"
      :model="form.data"
      :rules="form.rules"
      size="medium"
      hide-required-asterisk
    >
      <el-form-item prop="amount" label="提现金额">
        <el-input
          v-model="form.data.amount"
          clearable
          placeholder="请输入金额"
        >
        <span slot="suffix">元</span>
        </el-input>
      </el-form-item>
      <div class="text-line">
        <span class="label">账户名称：</span>
        <span>{{accountObj.bankaccount}}</span>
      </div>
      <div class="text-line">
        <span class="label">开户银行：</span>
        <span>{{accountObj.bankname}}</span>
      </div>
      <div class="text-line">
        <span class="label">银行卡号：</span>
        <span>{{accountObj.bankno}}</span>
      </div>
      <div style="color:#F4615A;margin-top:10px;">
        预计到账时间为申请后的1-3个工作日
      </div>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click="toggle(false)">取消</el-button>
        <el-button
          type="primary"
          :loading="form.loading"
          :disabled="form.loading"
          @click="confirm"
          >确定</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { REG_EXP } from '@/util'
  export default {
    name: 'SettleEdit',
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            amount: '',
          },
          rules: {
            amount: [
              {required: true, message: "必填项", trigger: "change"},
              {pattern: REG_EXP.money, message: "请填写正确的金额格式，如：188.88", trigger: "blur"}
            ],
          }
        },
        accountObj: {
          bankaccount:'',
          bankname:'',
          bankno:'',
        },
      }
    },
    methods: {
      // 获取详情
      getDetail(accountObj,balance) {
        if(balance != 0 || balance != '0.00') {
          this.form.data.amount = balance; // 待提现金额
        }
        this.isChange = true
        this.accountObj = accountObj; // 账号信息
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            this.form.loading = true;
            this.$http.post('/company/settlement/create', {amount:this.form.data.amount}).then(res => {
              if(res.code === 1) {
                this.$message.success('操作成功！')
                this.toggle(false)
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
           }).finally(() => {
             this.form.loading = false;
           })
          }
        })
      },
    }
  }
</script>
<style scoped>
.text-line {
  height: 40px;
  line-height: 40px;
}
.label {
  color: #999999;
}
</style>