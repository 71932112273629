<template>
  <div class="main-view">
    <div class="section">
      <div class="top">
        <el-row>
          <el-col :span="6">
            <div>到账总金额 (元)<i class="el-icon-warning-outline" style="margin-left:10px;"></i></div>
            <div class="sum">{{table.total_amount}}</div>
          </el-col>
          <el-col :span="6">
            <div>待提现金额 (元)<i class="el-icon-warning-outline" style="margin-left:10px;"></i></div>
            <div class="sum">{{table.balance}}</div>
          </el-col>
          <el-col :span="6">
            <div>已提现金额 (元)<i class="el-icon-warning-outline" style="margin-left:10px;"></i></div>
            <div class="sum">{{table.amount}}</div>
          </el-col>
          <el-col style="display:flex;justify-content: end;align-items: center;"  :span="6">
            <el-button type="primary"  @click='showEdit'>申请提现</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="filter__wrap">
        <el-form ref="elFormDom" inline :model="table.params" size="small">
          <el-form-item prop="keyword" >
            <el-input v-model="table.params.keyword" placeholder="提现人姓名">
              <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearch"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="deal_time">
            <el-date-picker
              v-model="table.params.deal_time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="处理开始日期"
              end-placeholder="处理结束日期"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              @change="onSearch">
            </el-date-picker>
          </el-form-item>
          <el-form-item prop="create_time">
            <el-date-picker
              v-model="table.params.create_time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="提现开始日期"
              end-placeholder="提现结束日期"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              @change="onSearch">
               <i slot="suffix" class="el-input__icon el-icon-date"></i>
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:status="{row}">
        <span v-if="row.status == 1" style="color:#FDA700">正在处理</span>
        <span v-if="row.status == 2" style="color:#4FCD46">提现完成</span>
      </template>
      <template v-slot:image="{row}">
        <el-image
          v-if="row.status == 2"
          style="width: 80px; height: 40px;"
          :src="row.image"
          fit="cover"
          :preview-src-list="[row.image]"></el-image>
        <span v-else>/</span>
      </template>
    </VTable>

    <edit ref="edit" @refresh="getTable"></edit>
    <detail ref="detail" ></detail>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
import Detail from './components/Detail.vue'
import { mixinTable } from '@/mixins/table.js'
import {getStorage} from '@/storage/index.js'
export default {
  name: 'AccountList',
  components: {
    VTable,
    Edit,
    Detail
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "company_account_name", label: "提现人姓名", hidden: false },
        { name: "amount", label: "提现金额（元）", hidden: false },
        { name: "bankno", label: "提现账户", hidden: false},
        { name: "create_time", label: "提现时间", hidden: false},
        { name: "status", label: "状态", width:"120", hidden: false},
        { name: "deal_time", label: "处理时间", hidden: false},
        { name: "image", label: "回单", width:"120", hidden: false},
      ],
      table: {
        loading: false,
        params: {
          keyword: '',
          deal_time:[],
          deal_time_start: '',
          deal_time_end: '',
          create_time: [],
          create_time_start: '',
          create_time_end: '',
          status: 1, // 筛选状态 1:待支付 2:待核销 3:已完成 4:已退款 5:已取消 默认为待支付
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        amount: 0, //已提现金额
        balance: 0, //待提现金额
        total_amount: 0 //总到账金额
      },
      curPowerArr: [],
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    // 查询列表
    getTable() {
      var _params = {...this.table.params}
      let deal_time = this.table.params.deal_time;
      let create_time = this.table.params.create_time;
      if(deal_time.length > 1) {
        _params.deal_time_start = deal_time[0]
        _params.deal_time_end = deal_time[1]
      } else {
        _params.deal_time_start = '';
        _params.deal_time_end = '';
      } 
      if(create_time.length > 1) {
        _params.create_time_start = create_time[0]
        _params.create_time_end = create_time [1]
      } else {
        _params.create_time_start = '';
        _params.create_time_end = '';
      }
      delete _params.deal_time
      delete _params.create_time
      this.$http.get('/company/settlement/list', {params: _params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.amount = res.data.amount;
          this.table.balance = res.data.balance;
          this.table.total_amount = res.data.total_amount;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit() {
      this.$http.get('/company/settlement/account',{params: {}}).then(res => {
        if(res.code == 1) {
          var accountObj = {
            bankno: res.data.bankno,
            bankname: res.data.bankname,
            bankaccount: res.data.bankaccount,
          }
          if(!!accountObj) {
            let dom = this.$refs.edit
            dom.toggle(true)
            dom.getDetail(accountObj, this.table.balance)
            dom = null
          } else {
            this.$message.warning('请先添加账号信息')
          }
        }
      })
      
    },
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
  }
}
</script>

<style scoped lang="scss">
.radio_group_wrap {
  margin:0 18px 18px;

  &::v-deep {
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      color: #0376E7;
      background-color: #E5F2FE;
      border-color: #0376E7;
    }
  }
}
.corlor1 {
  display: flex;
  align-items: center;
}
.corlor2 {
  display: flex;
  align-items: center;
}
.corlor3 {
  display: flex;
  align-items: center;
}
.corlor1::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #FFA10E;
  display: inline-block;
  margin-right: 5px;
}
.corlor2::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #27D144;
  display: inline-block;
  margin-right: 5px;
}
.corlor3::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #EE5050;
  display: inline-block;
  margin-right: 5px;
}
.text1 {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.top {
  padding: 18px 50px;
  border-bottom: solid 10px #f2f5fc;
}
.sum{
  font-size: 28px;
  font-weight: bold;
  color:#0376E7;
  margin-top: 10px;
}
</style>
